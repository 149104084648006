import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


function Network() {

  return (
    <section id="network">
      <Container>
        <Row className="intro">
          <Col md={7} className="text">
            <h3>
              Raggiungi il target più interessato ai progetti sostenibili della tua
              azienda con Now2030: un Network di Siti Premium dedicato alle
              tematiche ESG e una piattaforma
              specializzata sugli obiettivi
              dell’Agenda 2030 dell’ONU.
            </h3>
            Il contesto ideale, i formati più coinvolgenti, la tecnologia
            più performante per la reputazione ESG del tuo brand.
          </Col>
          <Col md={5} className="logo">
            <img src="img/loghi/logo_now_2030.png" alt="Now 2030" />
          </Col>
        </Row>
        <Row className="ico">
          <Col md={4}>
            <img src="img/ico/info.png" alt="Network" />
          </Col>
          <Col md={4}>
            <img src="img/ico/tecnologia.png" alt="tecnologia" />
          </Col>
          <Col md={4}>
            <img src="img/ico/branded.png" alt="Branded" />
          </Col>
        </Row>
        <Row className="info">
          <Col md={4}>
            <img src="img/ico/info.png" alt="Network" />
            <div className="text">
              Il primo Network Premium pubblicitario italiano, dedicato
              alle tematiche ESG. Un
              ambiente selezionato, fatto di
              Siti Premium e di Siti Verticali
              per raggiungere il target
              strategicamente più
              interessante per la
              comunicazione delle iniziative
              ESG della tua azienda.
            </div>
          </Col>
          <Col md={4}>
            <img src="img/ico/tecnologia.png" alt="tecnologia" />
            <div className="text">
              Una tecnologia proprietaria per il contextual advertising
              con algoritmi specializzati nella
              classificazione semantica dei
              contenuti in base ai diciassette obiettivi
              di sviluppo sostenibile (SDGs)
              dell’Agenda 2030 dell’ONU e
              alle Missioni del PNRR.
            </div>
          </Col>
          <Col md={4}>
            <img src="img/ico/branded.png" alt="Branded" />
            <div className="text">
              Formati esclusivi per il Branded
              Content e per la sua promozione.
              Realizza i contenuti più
              coinvolgenti insieme a content
              creator specialisti dei temi
              della sostenibilità e scegli tra diversi formati Native.
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Network;