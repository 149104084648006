import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function AppSm() {
    
  return (
    <section id="AppSm">
      <Container>
          <Row className="mb-5">
            <Col className="text-center mx-5">
              <img src="img/smartphoneApp.png" alt="Branded" className="img-fluid" />
            </Col>
          </Row>
      </Container>
      <div className='prova'>
        <Container>
          <Row className='mt-5'>
            <Col md={6} className="text">
              <h3>
                Prova l’APP e scopri il tuo target.
              </h3>
              <p>Seleziona obiettivi e traguardi della tua azienda e scopri il tuo target ideale.</p>
              <Button href="https://now2030.it/" variant="primary" target="_blank" rel="noopener noreferrer">Prova subito!</Button>
            </Col>
            <Col md={6} className="text-md-end smmo">
              <img src="img/smartphone-mockup.png" alt="App smartphone" />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
  
export default AppSm;