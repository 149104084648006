import React from 'react';
import { Navbar, Nav, NavItem, Container, Button } from 'react-bootstrap';
import { Link, Events, animateScroll as scroll, scroller } from 'react-scroll'
import { ReactComponent as Logo } from '../loghi/logo-SillaBit.svg';


class Headermenu extends React.Component {

  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {

    Events.scrollEvent.register('begin', function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register('end', function () {
      console.log("end", arguments);
    });
    
    window.addEventListener("scroll", this.resizeHeaderOnScroll);

  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }
  scrollToWithContainer() {

    let goToContainer = new Promise((resolve, reject) => {

      Events.scrollEvent.register('end', () => {
        resolve();
        Events.scrollEvent.remove('end');
      });

      scroller.scrollTo('scroll-container', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });

    });

    goToContainer.then(() =>
      scroller.scrollTo('scroll-container-second-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'scroll-container'
      }));
  }
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200,
      headerEl = document.getElementById("js-header");
    if (distanceY > shrinkOn) {
      headerEl.classList.add("smaller");
    } else {
      headerEl.classList.remove("smaller");
    }
  }

    render() {
      return (
        <header className="App-header" id="js-header">
            <Navbar className="header-navbar px-md-5" collapseOnSelect expand="lg" variant="light">
                <Container fluid>
                    <Navbar.Brand href="#home"><Logo/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                      <Nav>
                        <NavItem ><Link activeClass="active" to="home" spy={true} smooth={true} duration={500}>Home</Link></NavItem>
                        <NavItem ><Link activeClass="active" to="network" spy={true} smooth={true} duration={500}>Now2030</Link></NavItem>
                        <NavItem ><Link activeClass="active" to="adNetwork" spy={true} smooth={true} duration={500}>4w</Link></NavItem>
                        <NavItem ><Link activeClass="active" to="chiSiamo" spy={true} smooth={true} duration={500}>Chi siamo</Link></NavItem>
                        <NavItem><Button href="mailto:info@stillabit.com" variant="primary">Contattaci</Button> </NavItem>
                      </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
      );
    }
  };
  
  function Header() {
    
    return (
      <div>
        <Headermenu/>
      </div>
    );
  }
  
  export default Header;