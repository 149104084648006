import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ChiSiamo() {
    
  return (
    <section id="chiSiamo">
      <Container>
        <Row>
          <Col className="tit">
            Chi siamo
          </Col>
        </Row>
        <Row className="d-flex justify-content-between">
          <Col>
            <img src="img/loghi/logo-chi-siamo-sillabit.png" alt="Network"/>
            <div className="text">
              <p className="pb-3">Stillabit nasce nel 2023 dall’operazione di re-branding di 4w MarketPlace, network
              pubblicitario online leader del mercato italiano da oltre quindici anni, con l’obiettivo
              di riposizionare il brand su un segmento della comunicazione, quello della
              sostenibilità, che vede impegnate le aziende di tutte le dimensioni e di tutte le
              categorie a livello globale. A questo scopo, all’inizio del 2023, 4w acquisisce la startup
              Now2030, specializzata nella classificazione e gestione di inventory a target ESG.</p>

              <p>4w MarketPlace ha origine come startup dell’incubatore certificato Digital Magics,
              quotato alla borsa EGM. Dal 2009 si afferma nella gestione dei formati annunci
              native delle più importanti testate online italiane (esclusiva con il Consorzio PPNPremium
              Publisher Network, fondato da Gruppo RCS e Gruppo Editoriale L’Espresso,
              oggi GEDI). Nel corso degli anni il network si amplia e diviene il più imponente nel
              mercato del digital advertising. Nel 2015 il Gruppo DADA rileva il 25% del capitale di
              4w a fronte del conferimento del ramo d’azienda ProAdv Simply. 4w si impone con
                questa operazione anche nelle aree video e display.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
  
export default ChiSiamo;