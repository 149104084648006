import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function AdNetwork() {
    
  return (
    <section id="adNetwork">
      <Container>
        <Row className="mb-3 d-flex justify-content-center">
          <Col lg={8} md={10} className="text-center">
            <img src="img/loghi/logo4w.png" alt="Branded" className="img-fluid" />
            <h3>L’Ad-Network con oltre quindici anni di esperienza nel digital advertising.</h3>
          </Col>
        </Row>
        <Row className="mb-5 d-flex justify-content-center">
          <Col lg={8} md={10} className="text-center">
            <img src="img/idevices_mockup.png" alt="mockup" className="img-fluid" />
            <img src="img/ico_mockup.svg" alt="icone" className="img-fluid" />
            <h4>Competenza, trasparenza e affidabilità per una pianificazione online in totale sicurezza.</h4>
            La piattaforma proprietaria full-funnel specializzata nella
            comunicazione di prodotto per l’ottimizzazione delle campagne a performace e
            awareness con formati Native,
            Video e Display.
          </Col>
        </Row>
      </Container>
    </section>
  );
}
  
export default AdNetwork;