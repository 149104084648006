import React from 'react';
import "./styles/main.scss"

import { Element} from 'react-scroll'
import Header  from './components/header';
import Footer from './components/footer';
import Cover from './components/cover';
import Network from './components/network';
import AppSm from './components/appSm';
import AdNetwork from './components/adNetwork';
import ChiSiamo from './components/chi-siamo';

import  ScrollToTop from './components/scrollToTop';



function App() {
  return (
    <div className="App">
      <Element name="home" className="element" >
        <Header />
      </Element>
      <Element name="cover" className="element" >
        <Cover />
      </Element>
      <Element name="network" className="element" >
        <Network />
      </Element>
      <Element name="appSm" className="element" >
        <AppSm />
      </Element>
      <Element name="adNetwork" className="element" >
        <AdNetwork />
      </Element>
      <Element name="chiSiamo" className="element" >
        <ChiSiamo />
      </Element>

      <Element name="contatti" className="element" >
        <Footer />
      </Element>

      <ScrollToTop />
    </div>
  );
}

export default App;
