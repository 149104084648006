import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ReactComponent as Logo } from '../loghi/logo-SillaBit.svg';
import { Facebook, Instagram, Linkedin } from 'react-bootstrap-icons';

function Footer() {
    
    return (
      <div>
        <Container className="text-center mt-5">
          <Row>
            <Col>
              <p className="big">Sei un Editore del Network?</p>
              <Button href="https://publisher.4wmarketplace.com/login" variant="primary" target="_blank" rel="noopener noreferrer">ACCEDI</Button>
            </Col>
          </Row>
        </Container>
        <footer id="footer-page">
          <Container>
            <Row>
              <Col md={6} className="copyrights d-flex flex-column">
                <div className="logo">
                  <Logo />
                </div>
                <p className="powered"> Stillabit è una Società Benefit </p>
                <div className="social">
                  <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><Facebook size={30} /></a>
                  <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><Instagram size={30} /></a>
                  <a href="https://it.linkedin.com/" target="_blank" rel="noopener noreferrer"><Linkedin size={30} /></a>
                </div>
                <div className="mt-auto mb-md-0 mb-3">
                  <a href='https://www.stillabit.com/privacy/' target="_blank" rel="noopener noreferrer" className="me-4">Privacy Policy</a>
                  <a href='mailto:info@stillabit.com' target="_blank" rel="noopener noreferrer" className="me-4">Contact us</a>
                  <a href='https://www.stillabit.com/informativa-cookie/' target="_blank" rel="noopener noreferrer">Cookie policy e condizioni di utilizzo</a>
                </div>
              </Col>
              <Col md={6} className="credits d-flex align-items-md-end flex-column">
                <div className="mb-md-0 mb-3">
                  <span>Stillabit s.r.l. S.B.</span>
                  <span>Via Bernardo Quaranta, 40</span>
                  <span>20139 Milano - Italia</span>
                  <span>Tel. +39 02 525051</span>
                  <span>P.Iva 05969750966</span>
                </div>
                <div className="mt-auto">
                  <img src="/img/loghi/logo-iab.png" className="img-fluid me-2" alt="socio IAB" />
                  <img src="/img/loghi/logo-edaa.png" className="img-fluid mt-3" alt="socio Ed@a" />
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
  
  export default Footer;