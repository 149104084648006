import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Cover() {
    return (
      <div className="cover">
        <div className="description">
          <Container fluid>
            <Row className="d-flex justify-content-center">
              <Col xl={9} md={10}>
                <h1>
                  Nasce un nuovo modo di pianificare la tua comunicazione online.
                </h1>
                <h2>
                  La sostenibilità è una priorità nell’agenda di tutti noi, la comunicazione una leva straordinaria per questo processo di cambiamento.
                </h2>
                <Button variant="light" href="#network">Discover Stillabit</Button>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row xs="auto" className="d-flex align-items-end flex-column">
              <Col className="mt-auto me-5">
                <img src="/img/cit.png" className="cit" alt="«Il mondo, così come lo abbiamo creato, è il risultato del nostro modo di pensare. Non può essere cambiato senza cambiare il modo in cui pensiamo». Albert Einstein" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
  
  export default Cover;